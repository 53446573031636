import React from 'react'
import { navigate } from 'gatsby'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import SEO from '~components/seo'
import HeroSection from '~components/layouts/marketing/HeroSection'
import { useUserContext } from '~providers/user'
import { openCalendlyModal } from '~components/Shared/Functions'

const VirtualServicePage = () => {
  const { isAuthenticated } = useUserContext()
  const buttonMessage = isAuthenticated() ? 'Schedule An Appointment' : 'Log In To Schedule An Appointment'

  return (
    <Layout useCalendly>
      <SEO
        title="Virtual Repair Service"
        pathname="/customer-services/virtual-repair"
        description="Leather Spa makes it easy to get in touch even remotely. Our leather repair specialists are available virtually via video chat for consultation about how best to repair your shoes and leather accessories."
      />
      <Main className="mb-5">
        <HeroSection title={'Virtual Repair Service'} fluid={false}
          breadcrumb={{ title: 'At Your Service', path: '/customer-services' }}
          mobileImg={'https://leatherspa.imgix.net/VirtualServicePage.jpg?fit=scale&w=992'}
          desktopImg={'https://leatherspa.imgix.net/VirtualServicePage.jpg?fit=scale&w=1360'}
        >
          <>
            <h2>SCHEDULE A VIRTUAL ONE-ON-ONE CONSULTATION WITH A LEATHER SPA REPAIR EXPERT</h2>
            <p>
              We are pleased to now provide free virtual consultations with our repair specialists via video chat. Our experts are available to answer any questions you may have regarding your leather accessories by providing service pricing, repair quotes, and much more. Easily book an available appointment and we will email you simple instructions to follow once your appointment is confirmed.
            </p>
            <p>Repair your luxury leather accessories from the comfort of your home.</p>
            <button className="w-auto px-5 mt-4 btn btn-primary" onClick={isAuthenticated() ? openCalendlyModal : () => {navigate('/log-in')}}>
              {buttonMessage}
            </button>
          </>
        </HeroSection>
      </Main>
    </Layout >
  )
}

export default VirtualServicePage
